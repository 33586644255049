<template>
  <v-btn @click="onClick" fab :color="color" class="add-btn" :class="otherClass">
    <v-icon>ri-add-line</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    onClick: {
      type: Function,
      default: () => {}
    },
    color: {
      type: String,
      default: 'primary'
    },
    isFixed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    otherClass() {
      if(this.isFixed) return 'fixed-btn'
      return null
    },
  },
}
</script>

<style lang="sass">
.add-btn
  //background-color: #9EA4AD !important
  i
    color: #ffffff !important
</style>